import faviconDefault from 'img/favicon.svg?url';
import faviconArchive from 'img/favicon/faviconArchive.svg?url';
import faviconCode from 'img/favicon/faviconCode.svg?url';
import faviconDocs from 'img/favicon/faviconDocs.svg?url';
import faviconEbook from 'img/favicon/faviconEbook.svg?url';
import faviconFolder from 'img/favicon/faviconFolder.svg?url';
import faviconMusic from 'img/favicon/faviconMusic.svg?url';
import faviconPdf from 'img/favicon/faviconPdf.svg?url';
import faviconPhoto from 'img/favicon/faviconPhoto.svg?url';
import faviconPres from 'img/favicon/faviconPres.svg?url';
import faviconSpreadsheet from 'img/favicon/faviconSpreadsheet.svg?url';
import faviconUnknown from 'img/favicon/faviconUnknown.svg?url';
import faviconVideo from 'img/favicon/faviconVideo.svg?url';
import type { IExtInfo } from 'lib/extInfo';

export interface FaviconsInterface {
    svg?: string | null;
    ico?: string | null;
    appleTouchIcon?: string | null;
}

/**
 * Функция принимает информацию о расширении файла и возвращает фавиконку для него. Если передать null вернет стандартную фавиконку
 */
export function getFaviconByExtension(extInfo: IExtInfo | null, imgPath: string): FaviconsInterface {
    if (extInfo === null) {
        return {
            svg: faviconDefault,
            ico: `${imgPath}favicon.ico`,
            appleTouchIcon: '/apple-touch-icon.png',
        };
    }

    const faviconLight = {
        pdf: {
            svg: faviconPdf,
            ico: `${imgPath}favicon/faviconPdf.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-pdf.png`,
        },
        archive: {
            svg: faviconArchive,
            ico: `${imgPath}favicon/faviconArchive.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-archive.png`,
        },
        ebook: {
            svg: faviconEbook,
            ico: `${imgPath}favicon/faviconEbook.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-ebook.png`,
        },
        presentation: {
            svg: faviconPres,
            ico: `${imgPath}favicon/faviconPres.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-pres.png`,
        },
        spreadsheet: {
            svg: faviconSpreadsheet,
            ico: `${imgPath}favicon/faviconSpreadsheet.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-spreadsheet.png`,
        },
        text: {
            svg: faviconDocs,
            ico: `${imgPath}favicon/faviconDocs.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-docs.png`,
        },
        folder: {
            svg: faviconFolder,
            ico: `${imgPath}favicon/faviconFolder.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-folder.png`,
        },
        video: {
            svg: faviconVideo,
            ico: `${imgPath}favicon/faviconVideo.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-video.png`,
        },
        image: {
            svg: faviconPhoto,
            ico: `${imgPath}favicon/faviconPhoto.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-photo.png`,
        },
        audio: {
            svg: faviconMusic,
            ico: `${imgPath}favicon/faviconMusic.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-music.png`,
        },
        source: {
            svg: faviconCode,
            ico: `${imgPath}favicon/faviconCode.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-code.png`,
        },
        default: {
            svg: faviconUnknown,
            ico: `${imgPath}favicon/faviconUnknown.ico`,
            appleTouchIcon: `${imgPath}favicon/apple-touch-icon-unknown.png`,
        },
    };
    return faviconLight[extInfo.subKind] || faviconLight[extInfo.kind] || faviconLight['default'];
}

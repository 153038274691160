import { addHitmanInterceptors } from '@mail-core/std/fn/hitman';
import type { AbstractAxiosInstance } from '@mail-core/std/type';
// eslint-disable-next-line no-restricted-imports
import axios, {
    type AxiosInstance,
    type AxiosRequestHeaders,
    type AxiosResponse,
    type CancelTokenSource,
    type Method,
    type ResponseType,
} from 'axios';
import { getFeature } from 'Cloud/Application/FeaturesEs6';

const enableHitsProtection = getFeature('enable-hits-protection');
const hitmanDomains = getFeature('domains-with-hitman') || 'all';

export function setupAxiosWithHitman(axios: AbstractAxiosInstance) {
    if (enableHitsProtection) {
        addHitmanInterceptors({
            axios,
            corsDomainsWithHitman: hitmanDomains,
        });
    }
}

setupAxiosWithHitman(axios);

// Везде используем пропатченный хитманом инстанс axios (см. CLOUDWEB-17443)
export default axios;

// После обновления на  typescript 5 сможем просто писать export type * from 'axios'
export type { AxiosInstance, AxiosResponse, CancelTokenSource, Method, ResponseType, AxiosRequestHeaders };
